export const WIRED_SCREENSHARE_SET_DEVICE_CONNECTED
    = 'WIRED_SCREENSHARE_SET_DEVICE_CONNECTED';

export const WIRED_SCREENSHARE_SET_INPUT_AVAILABILITY
    = 'WIRED_SCREENSHARE_SET_INPUT_AVAILABILITY';

export const WIRED_SCREENSHARE_SET_INPUT_IDLE_VALUE
    = 'WIRED_SCREENSHARE_SET_INPUT_IDLE_VALUE';

export const WIRED_SCREENSHARE_SET_INPUT_LABEL
    = 'WIRED_SCREENSHARE_SET_INPUT_LABEL';
