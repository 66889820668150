
export const SET_IS_NIGHTLY_RELOAD_TIME = 'SET_IS_NIGHTLY_RELOAD_TIME';

export const SET_LAST_LOAD_TIME = 'SET_LAST_LOAD_TIME';

export const SET_OK_TO_UPDATE = 'SET_OK_TO_UPDATE';

export const SET_WEB_UPDATE_AVAILABLE = 'SET_WEB_UPDATE_AVAILABLE';

export const UPDATE_WEB_SOURCE = 'UPDATE_WEB_SOURCE';
