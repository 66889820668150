export const SPOT_REMOTE_API_JOIN_CODE_RECEIVED = 'SPOT_REMOTE_API_JOIN_CODE_RECEIVED';

export const SPOT_REMOTE_CONNECTION_FAILED = 'SPOT_REMOTE_CONNECTION_FAILED';

export const SPOT_REMOTE_COMPLETED_ONBOARDING = 'SPOT_REMOTE_COMPLETED_ONBOARDING';

export const SPOT_REMOTE_EXIT_SHARE_MODE = 'SPOT_REMOTE_EXIT_SHARE_MODE';

export const SPOT_REMOTE_JOIN_CODE_INVALID = 'SPOT_REMOTE_JOIN_CODE_INVALID';

export const SPOT_REMOTE_JOIN_CODE_VALID = 'SPOT_REMOTE_JOIN_CODE_VALID';

export const SPOT_REMOTE_SET_COUNTRY_CODE = 'SPOT_REMOTE_SET_COUNTRY_CODE';

export const SPOT_REMOTE_SET_MOST_RECENT_COUNTRY_CODE = 'SPOT_REMOTE_SET_MOST_RECENT_COUNTRY_CODE';

export const SPOT_REMOTE_WILL_VALIDATE_JOIN_CODE = 'SPOT_REMOTE_WILL_VALIDATE_JOIN_CODE';
